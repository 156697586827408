import React, { Component } from 'react';
import App from './App';
import WebFont from 'webfontloader';
import Velocity from 'velocity-animate';

export default class Root extends Component {

  constructor(props) {
    super(props);
    
    this.initialState = {
      fontsLoaded: false
    };

    this.state = this.initialState;
}

componentDidMount() {
  const parent = this;
  WebFont.load({
    google: {
      families: ['Montserrat:400,700', 'Open Sans:300italic,400italic,400,300,600,700']
    },
    active: function() {
      parent.setState({
        fontsLoaded : true
      });
        parent.fontsLoadedFunc();
    }
  });
}

  fontsLoadedFunc()
  {
    const element = parent.document.querySelectorAll('h1');
    Velocity(element, { opacity: 1 }, 2000); 
  }
  
  render() {
    const { fontsLoaded} = this.state;
    if (!fontsLoaded){
      return <div/>
    }

     return (
          <App showText={fontsLoaded}/> 
    );
  }
}

// Root.propTypes = {
//   fontsLoaded: PropTypes.object.isRequired,
// };
