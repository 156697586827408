/* eslint-disable import/no-named-as-default */
import React from "react";
import { hot } from "react-hot-loader";
import PropTypes from 'prop-types';
import Velocity from 'velocity-animate';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.

class App extends React.Component {

  constructor(props) {
    super(props);

    this.inNotOut = false;
    this.strList =
      ["","", "Hello",
      "My name is David Unsworth",
      "I am a contract software engineer",
      "based in Edinburgh, UK.",
      "I am a full-stack developer",
      "with extensive experience in", 
      "Enterprise Web Applications",
      "OO Design (SOLID, Clean Architecture, DDD)",
      ".NET, Java, Javascript, SQL",
      "Angular and React",
      "SQL Server and Oracle"

      ];
    this.it = 0;
    this.timeout = 0;
    this.options = {
      fadeTime: 500,
      onTime: 5000,
      offTime: 100,
      tagId: "h2"
    };
    this.state = "";

    this.that = this;
  }

  fade() {
    const element = parent.document.querySelectorAll(this.options.tagId);

    if (this.inNotOut) {
      Velocity(element, { opacity: 1 }, this.options.fadeTime);

      this.timeout = this.options.onTime;
    } else {
      Velocity(element, { opacity: 0 }, this.options.fadeTime);
      setTimeout(function (that) {

        that.it++;
        that.setState({msg: that.strList[that.it]});

        if (that.it >= that.strList.length-1) {
          that.it = 0;
        }
      }, this.options.fadeTime, this);
      this.timeout = this.options.fadeTime
        + this.options.offTime;
    }
    this.inNotOut = !this.inNotOut;

    setTimeout(function (that) {
      that.fade();
    }, this.timeout, this);
  }

  componentDidMount() {
    this.fade();
  }

  render() {
    const { showText } = this.props;

    if (!showText) {
      return <div />
    }

    const message = this.state;
    return (
      <div id='wrapper'>
        <h1>Owlsgate Ltd</h1>

        <SkillsBanner message={message.msg}></SkillsBanner>

        <p>contact: david@owlsgate.net</p>
      </div>
    );
  }
}

const SkillsBanner = ({ message }) => (
  <h2>{message}</h2>
)

App.propTypes = {
  showText: PropTypes.bool.isRequired,
};

SkillsBanner.propTypes = {
  message: PropTypes.string.isRequired
}

export default hot(module)(App);
